/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import georgiaHero from '../../images/locationsHeroes/hero-georgia.jpg'
import womanLaptopEnroll from '../../images/people/woman-laptop-enroll.jpg'

import { StaticImage } from 'gatsby-plugin-image'
import DynamicCTACard from '../../components/DynamicCTACard'
import SeoCtaBar from '../../components/SeoCtaBar'

import CobraBanner from '../../components/CobraBanner'

import {
  Accordion,
  Columns,
  Column,
  Hero,
  Link,
  LinkButton,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/Footer'
import header from '../../components/Header'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()
  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-plan',
    'disclaimer-evidence',
    'dsiclaimer-changenotice',
    'disclaimer-aetnabrand',
    'disclaimer-clicopyright',
    'disclaimer-incompletedescrip',
    'disclaimer-cmsmaterialid',
  ]

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            ' Apply for Aetna Medicare Plans in Georgia | Call 833-998-1009 ',
          description:
            'Learn more about Aetna Medicare plans in Georgia. Let us help you find the right plan and help answer any questions you might have. Call 833-998-1009',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/georgia',
        promoCode: '151548',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      ' Apply for Aetna Medicare Plans in Georgia | Call 833-998-1009 ',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={georgiaHero}
                  alt="elderly couple walking through forest and smiling"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1" color="light">
                    Aetna<sup>®</sup> Medicare in Georgia{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>

        <SeoCtaBar />

        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Aetna Medicare Insurance for Georgians
              </Typography>
              <Typography variant="body">
                With over 600,000 Medicare Advantage members in Georgia as of
                2018*, southern hospitality is alive and well in the Peach
                State. And with so many things to do and see in Georgia, make
                sure you’re spending your time and money on memorable
                experiences and not medical expenses. Whether you want to check
                out Stone Mountain Park or watch the dolphins off of Tybee
                Island, get the Medicare plan to help you get there in full
                health.
              </Typography>
              <StaticImage
                src="../../images/illustrations/georgia-stats.svg"
                alt="More than 600,000 people in Georgia receive benefits from a Medicare Advantage plan."
                layout="fixed"
                className="product-icons"
              />
              <Typography variant="legal">
                *Kaiser Family Foundation{' '}
                <a
                  href="https://www.kff.org/medicare/state-indicator/total-medicare-beneficiaries/"
                  target="_blank"
                >
                  https://www.kff.org/medicare/state-indicator/total-medicare-beneficiaries/
                </a>
              </Typography>
              <Typography variant="body">
                To learn more about the available Medicare benefits in your
                area, call to talk to a licensed agent about Aetna Medicare.
              </Typography>
            </>
          }
        ></VariableContent>

        <CobraBanner />

        <VariableContent
          backgroundColor="lightGray"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Types of Medicare plans in the Peach State
              </Typography>
              <Typography variant="body">
                Sit back, relax, and take a sip of your “homegrown” Coca-Cola
                (but not too many). There are several Medicare plans to choose
                from for Georgians! From Original Medicare to Medicare Advantage
                plans, you can find the plan that best suits you at an
                affordable rate.
              </Typography>
            </>
          }
        >
          <Stack>
            <Columns>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-health.svg"
                    alt="health icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare Advantage (Part C)
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/advantage"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-dollar.svg"
                    alt="dollar icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare HMO
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/hmo"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-hospital.svg"
                    alt="hospital icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Medicare PPO
                  </Typography>
                </div>
                <LinkButton
                  variant="feature"
                  to="/ppo"
                  color="primary"
                  className="margin-x-auto"
                >
                  Learn More
                </LinkButton>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-heart.svg"
                alt="heart icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                Am I eligible for Medicare in Georgia?
              </Typography>
              <Typography variant="body">
                Most Georgians 65 or older and legal US residents are eligible
                for Medicare. Some Georgians under 65 with certain health
                conditions may be eligible as well. Original Medicare (Parts A
                and B) gives you plenty of coverage, but for those seeking more
                benefits, Medicare Advantage plans have you covered. The plans
                may have vision, dental, hearing and prescription drug coverage.
              </Typography>
              <Typography variant="body">
                Medicare’s varied options have different levels of eligibility.
                If you have any questions or concerns regarding your eligibility
                for Medicare plans, including Aetna Medicare Advantage, make the
                call. A friendly, licensed agent can help.
              </Typography>
            </Column>
          </Columns>
        </VariableContent>

        <VariableContent backgroundColor="lightGray">
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-calendar.svg"
                alt="calendar icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">When can I enroll?</Typography>
              <Typography variant="body">
                There are three primary Medicare enrollment periods:
              </Typography>
              <Typography variant="h5">Initial Enrollment Period</Typography>
              <Typography variant="body">
                If you’re turning 65 soon and enrolling in Medicare for the
                first time, you have the month of your 65th birthday and a
                three-month grace period before and after your birthday known as
                the Initial Enrollment Period.
              </Typography>
              <Typography variant="h5">Annual Enrollment Period</Typography>
              <Typography variant="body">
                Already have Original Medicare and want to upgrade your coverage
                by switching to a Medicare Advantage plan? Make the switch
                during the Annual Enrollment Period which starts October 15th
                and ends December 7th every year.
              </Typography>
              <Typography variant="h5">Special Enrollment Period</Typography>
              <Typography variant="body">
                Under some circumstances, you may be able to enroll in a
                Medicare plan during a Special Enrollment Period. For example,
                you may be able to enroll in coverage or switch plans if you’ve
                recently moved to an area where your current plan is not
                covered. Talk to one of our licensed agents today to see if your
                special circumstance applies.
              </Typography>
              <Typography variant="h4" color="primary">
                Get enrolled in three simple steps:
              </Typography>
            </Column>
          </Columns>
          <Stack>
            <Columns>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-phone.svg"
                    alt="phone icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Call a licensed agent
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-checklist.svg"
                    alt="checklist icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Answer some questions about your coverage needs
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content bg-light">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-card.svg"
                    alt="card icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="primary">
                    Sign up for a plan
                  </Typography>
                </div>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../../images/icons/purple-magnify.svg"
                alt="magnifying glass icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column className="content-column">
              <Typography variant="h2">
                Other Medicare Resources in Georgia
              </Typography>
              <Stack spacing="xxl" alignMainContent="center">
                <Accordion
                  items={[
                    {
                      title:
                        'GeorgiaCares State Health Insurance Assistance Program (SHIP)',
                      content: (
                        <>
                          <Typography>
                            Whether you’re looking to volunteer or seeking help
                            for yourself, the{' '}
                            <a
                              href="https://mygeorgiacares.org/"
                              target="_blank"
                            >
                              GeorgiaCares Program
                            </a>{' '}
                            helps those needing assistance and counseling about
                            Medicare.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'HealthInsurance.org',
                      content: (
                        <>
                          <Typography>
                            Dig deep into Medicare information in Georgia with{' '}
                            <a
                              href="https://www.healthinsurance.org/"
                              target="_blank"
                            >
                              HealthInsurance.org
                            </a>
                            . Answering, “what is Medicare,” and more, get all
                            the information you need in one spot.
                          </Typography>
                        </>
                      ),
                    },
                    {
                      title: 'The Division of Aging Services (DAS)',
                      content: (
                        <>
                          <Typography>
                            From help at home to nutrition and wellness in mind,{' '}
                            <a
                              href="https://aging.georgia.gov/programs-and-services"
                              target="_blank"
                            >
                              Division of Aging Services
                            </a>{' '}
                            (DAS) looks to keep all Georgians happy and healthy
                            in their homes and communities.
                          </Typography>
                        </>
                      ),
                    },
                  ]}
                />
              </Stack>
            </Column>
          </Columns>
        </VariableContent>

        <SplitContent
          backgroundColor="lightGray"
          alignImageToBottom={false}
          image={
            <img
              src={womanLaptopEnroll}
              alt="an elderly woman enrolls in Medicare on her laptop"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Enroll in a Medicare Plan in Georgia today!
              </Typography>
              <Typography variant="body">
                Ready to enroll in a Medicare plan? Tap or click below to get
                started. If you need help during the enrollment process, contact
                a friendly licensed agent at{' '}
                <Link to={`tel:${rotatedNumber}`}>{rotatedNumber}</Link>. (TTY:
                711, available 24/7)
              </Typography>
              <div className="card bg-light">
                <Typography variant="h4">
                  Request your complimentary Medicare insurance quote{' '}
                </Typography>
                <LinkButton
                  variant="feature"
                  to="/form"
                  color="primary"
                  className="margin-x-auto"
                >
                  Get Quote
                </LinkButton>
              </div>
            </>
          }
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
